body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

header {
  padding-top: 1px;
  padding-bottom: 1px;
  width: 100%;
}

.nav {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
  margin: auto;
}

.logo {
  max-height: 7vh;
  padding-top: 0;
  margin-top: 0;
}

.logo:hover {
  transform: scale(1.1);
  transition: all 200ms;
}

.right {
  display: flex;
  margin-right: 20px;
}

.left {
  display: flex;
  padding: 8px;
}

.links {
  text-decoration: none;
  padding: 0px;
}

.button-75 {
  align-items: center;
  background-color: rgb(0, 0, 0, 0);
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Codec cold", sans-serif;
  font-size: 1vw;
  font-weight: 700;
  height: 5vh;
  width: auto;
  justify-content: center;
  letter-spacing: 0.4px;
  line-height: 1;
  max-width: 27vw;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-75:after {
  content: "";
  position: relative;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.button-75:hover:after {
  transform: scaleX(1.1);
  transform-origin: center;
}

.button-75 span {
  transition: all 200ms;
}

.button-75:hover span {
  transform: scale(1.1);
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(calc((100% - 100vw) / 2));
  z-index: -100;
  filter: brightness(30%);
}

.about {
  width: 100%;
  padding-bottom: 30px;
  padding-top: 5px;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.headshot {
  border-radius: 50%;
  width: 200px;
  border: 0.3vw solid #fff;
  /* box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #ad00e6,
    0 0 50px #9200e6; */
}

.name {
  font-size: 6vw;
  font-weight: bolder;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #ad00e6,
    0 0 50px #9200e6;
  margin-top: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 4vw;
  font-weight: bolder;
  /* text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #ad00e6,
    0 0 50px #9200e6; */
  margin-top: 10px;
  margin-bottom: 10px;
}

.bio {
  background-color: rgb(0, 0, 0, 0.6);
  width: 50%;
  font-size: 1.1em;
  margin: 0 auto;
  padding: 20px;
  border-radius: 25px;
}

.projDesc {
  margin-left: 10px;
  margin-right: 10px;
}

.techGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
}

.techText {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0;
}

.techIcon {
  width: 4vw;
  padding: 5px;
}

.techIcon:hover {
  transform: scale(1.2);
  transition: all 200ms;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10%;
}

.gitGraph {
  /* background-color: rgb(0, 0, 0, 0.6); */
  width: 50vw;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10%;
}

.projects {
  margin: 0 auto;
  max-width: 90vw;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.projTitle {
  color: white;
  font-size: 6vw;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #ad00e6,
    0 0 50px #9200e6;
}

.container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
  grid-auto-flow: row;
}

.projName {
  font-size: 5vw;
  text-shadow: 0 0 10px gray;
  margin-bottom: 5px;
}

.projectPics {
  width: 35vw;
  border: 4px solid white;
  border-radius: 25px;
}

.projectPics:hover {
  transform: scale(1.1);
  transition: all 200ms;
}

.description {
  width: 35vw;
  font-size: 1.3vw;
  background-color: rgb(0, 0, 0, 0.8);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
  border-radius: 25px;
}

.techUsed {
  font-weight: bolder;
  font-size: 1.5vw;
  text-decoration: underline;
  color: white;
  margin-top: 50px;
  margin-bottom: -10px;
}

.techLinks {
  font-weight: bolder;
  font-size: 1.5vw;
  text-decoration: underline;
  color: white;
  margin-bottom: 10px;
}

.icons {
  width: 4vw;
  background-color: white;
  border-radius: 50%;
  margin: 5px;
}

.icons:hover {
  transform: scale(1.2);
  transition: all 200ms;
}

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  background-color: rgb(0 0 0 / 75%) !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
  margin: 0 auto !important;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
  padding: 10px !important;
  padding-right: 13px !important;
  padding-bottom: 12px !important;
  background-color: #fff !important;
  width: 80% !important;
  height: 80% !important;
  margin: 0 auto;
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: black;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  margin: 0 auto !important;
}

.iframe {
  width: 100%;
  height: 100%;
}

.contact {
  padding-bottom: 30px;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.contactTitle {
  color: white;
  font-size: 6vw;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #ad00e6,
    0 0 50px #9200e6;
}

.conLogo {
  max-height: 25vh;
  max-width: 25vw;
  padding: 10px;
}

.conLogo:hover {
  transform: scale(1.2);
  transition: all 200ms;
}

@media screen and (max-width: 768px) {
  .button-75 {
    font-size: 11px;
    height: 50px;
    width: 76px;
  }

  .button-75 span {
    line-height: 50px;
  }

  .about {
    padding-top: 20px;
  }

  .headshot {
    border-radius: 50%;
    width: 50vw;
  }

  .bio {
    width: 80vw;
    font-size: 12px;
  }

  .techUsed {
    font-weight: bolder;
    font-size: 15px;
    text-decoration: underline;
    color: white;
    margin-top: 0px;
    margin-bottom: -5px;
  }

  .techLinks {
    font-weight: bolder;
    font-size: 15px;
    text-decoration: underline;
    color: white;
    margin-bottom: 10px;
  }

  .techIcon {
    width: 8vw;
    padding: 5px;
  }

  .container {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
    grid-auto-flow: row;
  }

  .projectPics {
    width: 75vw;
  }

  .ReactModal__Content {
    width: 80% !important;
    height: 30% !important;
    margin-right: -20px;
  }

  .description {
    width: 75vw;
    font-size: 3vw;
  }

  .icons {
    width: 9vw;
    border-radius: 50%;
    margin: 2px;
  }

  .nav {
    padding: 0px;
    padding-top: 8px;
  }

  .right {
    margin-right: 5px;
  }

  .left {
    margin-left: 5px;
  }

  footer {
    font-size: 10px;
  }
}
